exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-ai-at-kpoint-index-js": () => import("./../../../src/pages/ai-at-kpoint/index.js" /* webpackChunkName: "component---src-pages-ai-at-kpoint-index-js" */),
  "component---src-pages-author-index-js": () => import("./../../../src/pages/author/index.js" /* webpackChunkName: "component---src-pages-author-index-js" */),
  "component---src-pages-careers-details-index-js": () => import("./../../../src/pages/careers/details/index.js" /* webpackChunkName: "component---src-pages-careers-details-index-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-compliance-index-js": () => import("./../../../src/pages/compliance/index.js" /* webpackChunkName: "component---src-pages-compliance-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-content-center-index-js": () => import("./../../../src/pages/content-center/index.js" /* webpackChunkName: "component---src-pages-content-center-index-js" */),
  "component---src-pages-embed-index-js": () => import("./../../../src/pages/embed/index.js" /* webpackChunkName: "component---src-pages-embed-index-js" */),
  "component---src-pages-forms-getfab-index-js": () => import("./../../../src/pages/forms/getfab/index.js" /* webpackChunkName: "component---src-pages-forms-getfab-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manish-playlist-id-js": () => import("./../../../src/pages/manish/[playlistId].js" /* webpackChunkName: "component---src-pages-manish-playlist-id-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-platform-index-js": () => import("./../../../src/pages/platform/index.js" /* webpackChunkName: "component---src-pages-platform-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-koel-index-js": () => import("./../../../src/pages/products/koel/index.js" /* webpackChunkName: "component---src-pages-products-koel-index-js" */),
  "component---src-pages-products-play-index-js": () => import("./../../../src/pages/products/play/index.js" /* webpackChunkName: "component---src-pages-products-play-index-js" */),
  "component---src-pages-products-tube-index-js": () => import("./../../../src/pages/products/tube/index.js" /* webpackChunkName: "component---src-pages-products-tube-index-js" */),
  "component---src-pages-products-xpresso-index-js": () => import("./../../../src/pages/products/xpresso/index.js" /* webpackChunkName: "component---src-pages-products-xpresso-index-js" */),
  "component---src-pages-resources-blogs-details-index-js": () => import("./../../../src/pages/resources/blogs/details/index.js" /* webpackChunkName: "component---src-pages-resources-blogs-details-index-js" */),
  "component---src-pages-resources-blogs-index-js": () => import("./../../../src/pages/resources/blogs/index.js" /* webpackChunkName: "component---src-pages-resources-blogs-index-js" */),
  "component---src-pages-resources-casestudy-details-index-js": () => import("./../../../src/pages/resources/casestudy/details/index.js" /* webpackChunkName: "component---src-pages-resources-casestudy-details-index-js" */),
  "component---src-pages-resources-casestudy-index-js": () => import("./../../../src/pages/resources/casestudy/index.js" /* webpackChunkName: "component---src-pages-resources-casestudy-index-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-resources-kpoint-now-details-index-js": () => import("./../../../src/pages/resources/kpoint-now/details/index.js" /* webpackChunkName: "component---src-pages-resources-kpoint-now-details-index-js" */),
  "component---src-pages-resources-kpoint-now-index-js": () => import("./../../../src/pages/resources/kpoint-now/index.js" /* webpackChunkName: "component---src-pages-resources-kpoint-now-index-js" */),
  "component---src-pages-resources-miscvideos-index-js": () => import("./../../../src/pages/resources/miscvideos/index.js" /* webpackChunkName: "component---src-pages-resources-miscvideos-index-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("./../../../src/pages/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */),
  "component---src-pages-usecases-index-js": () => import("./../../../src/pages/usecases/index.js" /* webpackChunkName: "component---src-pages-usecases-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

