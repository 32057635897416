// Function to send the video play event to Google Analytics
export const sendVideoPlayEvent = (videoTitle) => {
  window.gtag("event", "video_play", {
    event_category: "Video", // Categorizes the event under 'Video'
    event_label: videoTitle, // Labels the event with the video's title
    page_location: window.location.href, // Sends the current page URL
  });
};

// Function to send the video_pause event to Google Analytics
export const sendVideoPauseEvent = (videoTitle) => {
  window.gtag("event", "video_pause", {
    event_category: "Video", // Categorizes the event under 'Video'
    event_label: videoTitle, // Labels the event with the video's title
    page_location: window.location.href, // Sends the current page URL
  });
};

// Function to send the video_loaded event to Google Analytics
export const sendVideoLoadedEvent = (videoTitle) => {
  window.gtag("event", "video_loaded", {
    event_category: "Video", // Categorizes the event under 'Video'
    event_label: videoTitle, // Labels the event with the video's title
    page_location: window.location.href, // Sends the current page URL
  });
};

// Function to send video progress event to Google Analytics
export const sendVideoProgressEvent = (progress, videoTitle) => {
  if (window.gtag) {
    window.gtag("event", "video_progress", {
      event_category: "Video", // Categorizes the event under 'Video'
      event_label: videoTitle, // Labels the event with the video's title
      progress: progress, // Records the progress milestone (e.g., 25%, 50%, 75%, 100%)
      page_location: window.location.href, // Sends the current page URL
    });
  }
};

// Function to check if this is the first visit by the user
export const checkFirstVisit = () => {
  setTimeout(() => {
    if (!localStorage.getItem("firstVisit")) {
      // If this is the user's first visit, mark it
      localStorage.setItem("firstVisit", "true");

      // Send first visit event to Google Analytics
      window.gtag("event", "first_visit", {
        event_category: "User Engagement", // Categorizes the event under 'User Engagement'
        page_location: window.location.href, // Sends the current page URL
      });
    }
  }, 100); // Delay execution to ensure proper loading
};

// Function to track scroll depth on the page
export const trackScrollDepth = () => {
  let hasScrolled50 = false; // Flag for 50% scroll depth
  let hasScrolled100 = false; // Flag for 100% scroll depth

  // Listen for scroll events
  window.addEventListener("scroll", () => {
    const scrollPosition = window.scrollY + window.innerHeight; // Current scroll position
    const pageHeight = document.documentElement.scrollHeight; // Total height of the page

    const scrollPercentage = (scrollPosition / pageHeight) * 100; // Calculate scroll percentage

    // Check if the user has scrolled past 50%
    if (scrollPercentage >= 50 && !hasScrolled50) {
      hasScrolled50 = true; // Mark that 50% scroll has been reached
      window.gtag("event", "scroll_50", {
        event_category: "User Engagement", // Categorizes the event under 'User Engagement'
        page_location: window.location.href, // Sends the current page URL
      });
    }

    // Check if the user has scrolled past 100%
    if (scrollPercentage >= 100 && !hasScrolled100) {
      hasScrolled100 = true; // Mark that 100% scroll has been reached
      window.gtag("event", "scroll_100", {
        event_category: "User Engagement", // Categorizes the event under 'User Engagement'
        page_location: window.location.href, // Sends the current page URL
      });
    }
  });
};

// Function to send time spent on page event to Google Analytics
export const sendTimeSpentEvent = (startTime) => {
  const endTime = new Date().getTime(); // Get the current time
  const timeSpent = Math.round((endTime - startTime) / 1000); // Calculate time spent in seconds

  if (window.gtag) {
    window.gtag("event", "time_on_page", {
      event_category: "User Engagement", // Categorizes the event under 'User Engagement'
      time_spent: `${timeSpent}_seconds`, // Sends the time spent on the page
      page_location: window.location.href, // Sends the current page URL
    });
  }
};

// Function to track social media share events
export const socialMediaShare = (platform, sharingUrl = "") => {
  if (window.gtag) {
    window.gtag("event", "social_share", {
      event_category: "User Engagement", // Categorizes the event under 'User Engagement'
      platform: platform, // The platform being used for sharing (e.g., Facebook, Twitter)
      sharingUrl: sharingUrl, // Optional: URL being shared
      page_location: window.location.href, // Sends the current page URL
    });
  }
};

// Function to track when the contact form is opened
export const trackContactFormOpened = (eventTriggeredFrom) => {
  if (window.gtag) {
    window.gtag("event", "contact_form_opened", {
      event_category: "User Engagement", // Categorizes the event under 'User Engagement'
      event_triggered_from: eventTriggeredFrom, // Where the event was triggered from
      page_location: window.location.href, // Sends the current page URL
    });
  }
};

// Function to track when the contact form is submitted
export const trackContactFormSubmitted = (eventTriggeredFrom) => {
  if (window.gtag) {
    window.gtag("event", "contact_form_submitted", {
      event_category: "User Engagement", // Categorizes the event under 'User Engagement'
      event_triggered_from: eventTriggeredFrom, // Where the event was triggered from
      page_location: window.location.href, // Sends the current page URL
    });
  }
};

// Function to track when the contact form is closed without submission
export const trackContactFormClosed = (eventTriggeredFrom) => {
  if (window.gtag) {
    window.gtag("event", "contact_form_closed_without_submission", {
      event_category: "User Engagement", // Categorizes the event under 'User Engagement'
      event_triggered_from: eventTriggeredFrom, // Where the event was triggered from
      page_location: window.location.href, // Sends the current page URL
    });
  }
};

// Function to send event when a job description is opened
export const sendJobDescriptionOpened = (jobTitle) => {
  if (window.gtag) {
    window.gtag("event", "Job_description_opened", {
      event_category: "User Engagement", // Categorizes the event under 'User Engagement'
      event_label: jobTitle, // Labels the event with the job title
      page_location: window.location.href, // Sends the current page URL
    });
  }
};

// Function to send event when a resume is submitted
export const SendSubmittedResume = (jobTitle) => {
  if (window.gtag) {
    window.gtag("event", "Submitted_the_resume", {
      event_category: "User Engagement", // Categorizes the event under 'User Engagement'
      event_label: jobTitle, // Labels the event with the job title
      page_location: window.location.href, // Sends the current page URL
    });
  }
};
